'use client';
import { Box, Container, Heading } from '@chakra-ui/react';
import { getImageProps } from 'next/image';
import { Autoplay, EffectFade, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import LinkCustom from '@/app/_components/link/LinkCustom';
import { ImageTransformationKeys } from '@/types/files/files.types';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './BannerSection.module.scss';

type BlogHeaderProps = {
  headerTitle: string;
  headerContent: string;
  mainImage: string | null;
  buttonText: string;
  buttonUrl: string;
};
const BlogHeader = ({
  data,
  imageKeys,
}: {
  data: BlogHeaderProps;
  imageKeys: ImageTransformationKeys;
}) => {
  const common = {
    alt: 'Hero',
    width: 1440,
    height: 680,
    priority: true,
    loader: directusImageLoader,
  };
  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...common,
    src: `${data?.mainImage ?? ''}?key=${imageKeys.mobileKey}`,
  });
  const {
    props: { srcSet: desktop, ...rest },
  } = getImageProps({
    ...common,
    src: `${data?.mainImage ?? ''}?key=${imageKeys.desktopKey}`,
  });

  return (
    <>
      <Swiper
        effect={'fade'}
        autoplay={{
          delay: 4500,
        }}
        loop={true}
        navigation={false}
        modules={[EffectFade, Navigation, Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        className={Styles.heroSwiper}
      >
        <SwiperSlide>
          <Box
            className={Styles.heroSwiperWrap}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'column'}
            height={{ base: 'auto', md: '681px' }}
          >
            <Container
              className={Styles.heroBannerContentWrapper}
              maxW={'1280'}
              width={'100%'}
              zIndex={1}
              paddingX={{ base: 6, '2xl': 0 }}
            >
              <Box className={Styles.heroBannerContent}>
                <Box
                  className={Styles.headingElement}
                  maxW={'780px'}
                  width={'100%'}
                  paddingY={{ base: 12, md: 0 }}
                >
                  <Heading
                    as="h1"
                    className={Styles.titleXl}
                    color={'white'}
                    marginBottom={8}
                  >
                    {data?.headerTitle}
                  </Heading>
                  {data?.buttonText && (
                    <LinkCustom
                      role="link"
                      linkText={data?.buttonText}
                      className="btnWhite"
                      moduleClass={Styles.bannerCta}
                      href={data?.buttonUrl}
                    />
                  )}
                </Box>
              </Box>
            </Container>
            <Box className={Styles.heroImageWrapper} width={'100%'}>
              <picture className={Styles.heroImage}>
                <source media="(max-width: 575px)" srcSet={mobile} />
                <source media="(min-width: 576px)" srcSet={desktop} />
                <img {...rest} alt="hero-banner-image" />
              </picture>
            </Box>
          </Box>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default BlogHeader;
