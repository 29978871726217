'use client';
import { Box, Container, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { getImageProps } from 'next/image';

import LinkCustom from '@/app/_components/link/LinkCustom';
import { getImageLink } from '@/services/link.service';
import { CallToAction } from '@/types/home/home.types';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './TrustSection.module.scss';

const TrustSection = ({ footer_cta }: { footer_cta: CallToAction }) => {
  const imageKeys = { desktopKey: 'image-693-377', mobileKey: 'image-360-170' };
  const common = {
    alt: 'Hero',
    width: 693,
    height: 377,
    loader: directusImageLoader,
  };
  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...common,
    src: getImageLink(footer_cta?.image)
      ? `${getImageLink(footer_cta?.image)}?key=${imageKeys.mobileKey}`
      : '',
  });

  const {
    props: { srcSet: desktop, ...rest },
  } = getImageProps({
    ...common,
    src: getImageLink(footer_cta?.image)
      ? `${getImageLink(footer_cta?.image)}?key=${imageKeys.desktopKey}`
      : '',
  });

  return (
    <section className={Styles.TrustSectionWrap}>
      <Container
        maxWidth={{ base: '100%', md: 1160 }}
        alignContent={'center'}
        paddingX={{ md: 6, lg: 0 }}
        paddingTop={{ sm: 20, md: '100px' }}
        paddingBottom={{ base: 20, md: '100px' }}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          alignItems={{ base: 'flex-start', md: 'center' }}
        >
          <Box marginX={{ sm: 'auto', md: 'initial' }}>
            <picture>
              <source media="(max-width: 575px)" srcSet={mobile} />
              <source media="(min-width: 576px)" srcSet={desktop} />
              <img {...rest} alt="trad-in-image" />
            </picture>
          </Box>
          <Box
            className={Styles.headingElement}
            paddingLeft={{ base: 6, md: 14, lg: 20 }}
            paddingRight={{ base: 6, md: 0 }}
            paddingTop={{ base: 14, md: 0 }}
            marginX={{ sm: 'auto', md: 'initial' }}
          >
            <Heading
              as="h3"
              className={Styles.titleLg}
              color={'white'}
              marginBottom={{ base: 4, md: 5 }}
            >
              {footer_cta?.heading}
            </Heading>
            <Text
              className={Styles.titleText}
              marginBottom={{ base: 5, sm: 8 }}
            >
              {footer_cta?.subheading}
            </Text>
            <LinkCustom
              className="btnWhite"
              moduleClass={Styles.btnCTA}
              role="link"
              href={footer_cta?.url}
            >
              {footer_cta?.text}
            </LinkCustom>
          </Box>
        </SimpleGrid>
      </Container>
    </section>
  );
};

export default TrustSection;
