'use client';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import LinkCustom from '@/app/_components/link/LinkCustom';
import { ReactComponent as ArrowBackwardCircle } from '@/app/_icons/ArrowBackwardCircleDark.svg';
// eslint-disable-next-line import/order
import { ReactComponent as ArrowForwardCircle } from '@/app/_icons/ArrowForwardCircleDark.svg';
import {
  InstagramFeedItem,
  MediaTypeEnum,
  SocialMediaProps,
} from '@/types/blog/blog.types';

// Import Swiper Styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Styles from './SocialMedia.module.scss';

const SocialMedia = ({ instagramData }: SocialMediaProps) => {
  const swiperRef = useRef<any>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      const updateNavigation = () => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      };

      swiper.on('slideChange', updateNavigation);
      swiper.on('init', updateNavigation);

      // Initial update
      updateNavigation();

      // Cleanup event listeners on component unmount
      return () => {
        swiper.off('slideChange', updateNavigation);
        swiper.off('init', updateNavigation);
      };
    }
  }, [swiperRef, setIsBeginning, setIsEnd]);

  const CustomNavigation = () => {
    return (
      <div className={Styles.socialMediaNavigation}>
        <button
          onClick={goPrev}
          className={`${Styles.socialMediaSwiperPrev} ${isBeginning ? Styles.disabled : ''}`}
          disabled={isBeginning}
        >
          <ArrowBackwardCircle />
        </button>
        <button
          onClick={goNext}
          className={`${Styles.socialMediaSwiperNext} ${isEnd ? Styles.disabled : ''}`}
          disabled={isEnd}
        >
          <ArrowForwardCircle />
        </button>
      </div>
    );
  };

  return (
    <>
      <Box className={Styles.socialMediaWrapper} paddingBottom={'100px'}>
        <Container maxW={1280} width={'100%'} paddingX={{ base: 6, '2xl': 0 }}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            flexWrap={'wrap'}
            alignItems={{ base: 'start', md: 'center' }}
            marginBottom={{ base: 16, md: 10 }}
            className={Styles.socialMediaHeader}
          >
            <Box className={Styles.headingElement}>
              <Text fontSize="xs" textTransform={'uppercase'} marginBottom={4}>
                LATEST ARTICLES
              </Text>
              <Heading as="h2" className={Styles.titleLg}>
                Social Media
              </Heading>
            </Box>
            <Box
              mt={{ base: 8, md: 0 }}
              marginLeft={{ md: 'auto' }}
              className={Styles.socialMediaHeaderCTA}
            >
              <LinkCustom
                role="link"
                href="/blog/social-media"
                className="btnOutline"
              >
                View all
                <ChevronRightIcon width={4} height={4} marginLeft={3} />
              </LinkCustom>
            </Box>
          </Flex>
          <Swiper
            className={Styles.socialMediaSlider}
            ref={swiperRef}
            slidesPerView={3}
            spaceBetween={40}
            modules={[Pagination, Navigation]}
            pagination={{
              el: Styles.socialMediaNavigation,
              type: 'fraction',
              formatFractionCurrent: function (number) {
                return '0' + number;
              },
            }}
            navigation={{
              nextEl: Styles.socialMediaSwiperNext,
              prevEl: Styles.socialMediaSwiperPrev,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              481: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              641: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            {instagramData.data.map((feed: InstagramFeedItem) => {
              const { id, media_url, media_type } = feed;

              return (
                <SwiperSlide key={id}>
                  <Link href="/blog/article" role="link">
                    {media_type === MediaTypeEnum.VIDEO ? (
                      <video
                        playsInline
                        autoPlay
                        loop
                        muted
                        className={Styles.socialMediaImg}
                      >
                        <source src={media_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <Image
                        className={Styles.socialMediaImg}
                        src={media_url}
                        width={400}
                        height={400}
                        alt={''}
                      />
                    )}
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Box
            className={Styles.socialMediaSwiperControls}
            mt={10}
            mb={{ base: 10, md: 0 }}
          >
            <CustomNavigation />
          </Box>
          <Box className={Styles.socialMediaHeaderCTAmobile}>
            <LinkCustom
              role="link"
              aria-label="View All"
              className="btnOutline"
              moduleClass={Styles.btnCTA}
              href="/blog/social-media"
            >
              View all
              <ChevronRightIcon width={4} height={4} marginLeft={3} />
            </LinkCustom>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SocialMedia;
